function scrollChanges() {
	var $headerNode = $('.fixed'),
	$headerChild = $headerNode.children('.center-content'),
	y = window.pageYOffset;

	if (y > 60) {
		$headerNode.addClass('scrolled');
		$headerChild.removeClass('inner');
	}

	else {
		$headerNode.removeClass('scrolled');
		$headerChild.addClass('inner');
	}
}

function initMap() {

	if (document.getElementById('googleMap') !== null) {

		// this (coordinatesObject) was passed by Wordpress with wp_localize_script on functions.php
		var coordinates = JSON.parse(coordinatesObject),
		place = new google.maps.LatLng( parseFloat(coordinates.lat), parseFloat(coordinates.long) );

		var map = new google.maps.Map(document.getElementById('googleMap'), {
			center: place,
			zoom: 15,
			styles: mapStyles,
		});

		var marker = new google.maps.Marker({
			position: place,
			map: map,
		});
	}
}

function showTeacher() {
	var instance = $(this).data('teacher'),
	toOpen = $('.teacher-instance[data-teacher="'+instance+'"]');
	$('.overlay').addClass('teacher-open').fadeIn(400, function() {
		toOpen.addClass('is-open').fadeIn();
	});
};

function hideTeachers() {
	$('.teacher-instance').fadeOut(400, function() {
		$('.teacher-instance').removeClass('is-open');
		$('.overlay').fadeOut().removeClass('teacher-open');
	});
}

function mobileMenu() {
	$(this).toggleClass('is-active');
	$('.header-links').toggleClass('is-open');
}

function openSubMenu(e) {
	var target =  $(event.target);
	target.children('.submenu').addClass('submenu-open');
	setTimeout(function() {
		target.addClass('submenu-open');
		$('.close-submenu').addClass('submenu-open');
	}, 400);

	if ($(event.target).is('a')) {
		$(event.target).parents('li').addClass('submenu-open');
	}
}

function closeSubMenu() {
	$(this).removeClass('submenu-open');
	setTimeout(function() {
		$('.has-submenu, .submenu').removeClass('submenu-open');
	}, 200);
}

function closeCta() {
    $(this).parents('div').addClass('clicked');
}

function deferIframes() {
  var src =  $('iframe').attr('data-src');
  $('iframe').prop('src', src);
}

function ModalRoll() {
	setTimeout(() => {
		MicroModal.show('modal-roll')

		let container = document.querySelector('.modal__container')
		setTimeout(() => {
			container.scrollTo({top: 0, behavior: 'smooth'})
		}, 1500)
	}, 5000)
}

/**
 * @method fetchResults
 * @param {String} url | URL to fetch
 * @param {String} actionName | WP action name
 * @param {Object} body | POST body if exists
 * @description Fetch helper for wordpress
*/

const fetchResults = (url, actionName, body) => {
	const config = {
		method: typeof body === undefined || null ? 'GET' : 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
		},
		credentials: 'same-origin'
	}

	if (body) {
		config.body = JSON.stringify(body)
	}

	let request = fetch(`${url}?action=${actionName}`, config)
		.then(response => response.json())

	return request;
}

const sendMail = e => {
	e.preventDefault();

	const { adminURL } = helpers.coordinatesObject;

	const fData = new FormData(e.target);
	let fieldJSON = {};

	// From array to JSON
	for (var pair of fData.entries()) {
		fieldJSON[pair[0]] = pair[1];
	}

	let btn = e.target.querySelector('button');

	btn.disabled = true;
	let oldText = btn.textContent;
	btn.textContent = 'ENVIANDO...';

	let submit = fetchResults(adminURL, 'roll_submit_emails', fieldJSON);

	submit.then(res => {
		if (res.status === 'success') {
			Sweetalert2.fire({
				'icon': 'success',
				'title': 'Obrigado!',
				'text': 'Sua mensagem foi enviada com sucesso.',
				'footer': 'Retornaremos o mais breve possível ou no horário pedido.'
			});

			ga('gtag_UA_124866706_1.send', 'event', 'Formulário', 'Envio', 'Formulários de Contato');

			if(res.form === 'modal') {
				setCookie('formModal', '1', '1000000');
			}

			e.target.reset();

			

			const elementModalRoll = document.getElementById("modal-roll");
			elementModalRoll.classList.remove("is-open");


		} else {
			Sweetalert2.fire({
				'icon': 'error',
				'title': 'Ops!',
				'text': 'Hove um erro no envio da sua mensagem.',
				'footer': 'Por favor tente novamente mais tarde ou entre em contato através de um de nossos telefones.'
			});

			btn.disabled = false;

		}

		btn.textContent = oldText;
	});
}

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	let expires = "expires="+d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}